import React, { useEffect } from 'react';

const BookingWidget = () => {
  useEffect(() => {
    // Get URL parameters
    const queryParams = new URLSearchParams(window.location.search);
    const accountId = queryParams.get('id') ;
    const locationId = queryParams.get('loc');
    const themeBackgroundColor = '#'+queryParams.get('color') ;

    // Set the embedded booking config on the window object
    window.embeddedBookingConfig = {
      accountId,
      locationId,
      themeBackgroundColor,
    };

    // Create the script element for the booking bundle
    const script = document.createElement('script');
    script.src = 'https://www.aissie.com.au/booking-bundle.js';
    script.defer = true;
    // Set the script attributes dynamically
    script.setAttribute('accountId', accountId);
    script.setAttribute('locationId', locationId);
    script.setAttribute('themeBackgroundColor', themeBackgroundColor);

    // Append the script to the document body
    document.body.appendChild(script);

    // Optional: Clean up the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="aissie-booking"></div>;
};

export default BookingWidget;
