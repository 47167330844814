// Card.js
import React, { useState, useEffect, useRef } from 'react';

const Card = ({ frontContent, backContent, colorClass, className }) => {
  const [showBack, setShowBack] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    if (!isMobile) return; // Only run on mobile devices

    const handleScroll = () => {
      if (cardRef.current) {
        const rect = cardRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const middleOfViewport = viewportHeight / 2;

        if (rect.top <= middleOfViewport && rect.bottom >= middleOfViewport) {
          setShowBack(true);
        } else {
          setShowBack(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handler to toggle the card flip
  const handleFlip = () => {
    setShowBack((prevShowBack) => !prevShowBack);
  };

  return (
    <div
      className={`${className} ${showBack ? 'flipped' : ''}`}
      ref={cardRef}
    >
      <div
        className={`card-front ${colorClass}`}
        onClick={handleFlip} // Added onClick handler
        style={{ cursor: 'pointer' }} // Optional: change cursor to indicate clickable
      >
        {frontContent}
      </div>
      <div
        className={`card-back ${colorClass}`}
        onClick={handleFlip} // Added onClick handler
        style={{ cursor: 'pointer' }} // Optional: change cursor to indicate clickable
      >
        {backContent}
      </div>
    </div>
  );
};

export default Card;
